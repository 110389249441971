<template>
  <div id="Incident"
       ref="Incident">
    <!-- 事件 -->
    <aside>
      <div></div>
      <div>
        <Input search
               v-model.trim="searchName"
               @on-search="search"
               @on-clear="clearSearch"
               placeholder="搜索事件"
               style="width: 290px;margin-right:20px"
               clearable />
        <Button icon="ios-compass"
                @click="toMap"
                v-show="listmode">地图</Button>
        <Button icon="md-list"
                @click="toList"
                v-show="!listmode">列表</Button>
      </div>
      <div class="back"
           @click="backhomepage">
        返回主页
      </div>
    </aside>
    <header v-if="listmode">
      <Select @on-change="typeChange"
              style="width: 200px">
        <Option v-for="item in typeChangeList"
                :value="item.id"
                :key="item.id">{{ item.e_type_name }}</Option>
      </Select>
      <div>
        <Button class="type-btn"
                style="border:1px solid #E93738;border-radius: 5px;"
                type="error"
                ghost
                @click="uncheck">不选择事件直接进入</Button>
        <Button icon="md-add"
                class="type-btn"
                style="border:1px solid #2183F3; color:#2183F3;border-radius: 5px;"
                ghost
                @click="typeModal = true">事件类型</Button>
      </div>
    </header>
    <main v-if="listmode">
      <!-- 新增事件 -->
      <div class="incident-add"
           @click="addModalshow">
        <Icon type="md-add-circle"
              color="#CBCBCB"
              size="33" />
        <p style="font-size: 14px; margin-top: 8px">新增事件</p>
      </div>
      <!-- 事件列表 -->
      <div v-for="item in eventList"
           :key="item.id"
           :class="['incident-box',checkID == item.id ? 'check-box' : '']">
        <span class="type-name"
              @click="checkedEvent(item)">{{ item.e_type_name }}</span>
        <div v-if="checkID == item.id"
             class="selected">当前选择</div>
        <div :id="'map' + item.id"
             class="map"
             @click="checkedEvent(item)"></div>
        <div class="info">
          <p class="title"
             @click="checkedEvent(item)"
             :title="item.e_name">{{ item.e_name }}</p>
          <span>{{ item.e_date }}</span>
          <div>
            <Tooltip max-width="800"
                     v-if="item.e_remarks">
              <p class="remark"
                 @click.stop="edit(item)">{{ item.e_remarks}}</p>
              <div slot="content">
                {{ item.e_remarks }}
              </div>
            </Tooltip>
            <p v-else
               class="remark"
               @click.stop="edit(item)">点击此处添加备注</p>
          </div>
          <!-- 操作 -->
          <div class="more"
               @click="showMore(item.id)">
            <Icon type="md-more"
                  size="22" />
            <div :style="{display: moreAct == item.id ?'block':'none'}">
              <p @click.stop="edit(item)">
                <Icon type="md-create"
                      style="margin-right: 0.15rem" />编辑
              </p>
              <p @click.stop="del(item)">
                <Icon type="ios-trash"
                      style="margin-right: 0.15rem" />删除
              </p>
            </div>
          </div>

        </div>
      </div>

      <div style="margin-top: 0.15rem; clear: both"
           v-if="total > 14">
        <Page :total="total"
              show-total
              size="small"
              style="text-align: center"
              :current="parseInt(page)"
              @on-change="changePage" />
      </div>
    </main>
    <section v-if="!listmode">
      <div class="typeList">
        <div v-for="(item,index) in typeChangeList"
             :class="[currentType == item.id ? 'check-box' : '']"
             @click="typeChange(item.id)"
             :key='index'>{{item.e_type_name}}</div>
        <div style="background:#707070;color:#ccc"
             @click="typeModal = true">+ 类型编辑</div>
      </div>
      <div id="bigmap"></div>
      <div class="hierarchyBar"
           :style="{opacity:mapstyleshow?1:0.7}"
           ref="hierarchyBar">
        <img src="@/assets/img/Icon/hierarchy.png"
             @click="mapstyleshow = !mapstyleshow" />
        <div class="hierarchyBox"
             v-show="mapstyleshow">
          <!-- <div>
                <Slider v-model="maskOpacity"
                        show-tip="never"
                        :min="30"
                        @on-input="changeMask"></Slider>
              </div> -->
          <Row type="flex"
               align="middle">
            <Col span="4">
            <Icon type="ios-contrast"
                  size="18" />
            </Col>
            <Col span="20">
            <Slider v-model="maskOpacity"
                    show-tip="never"
                    :min="30"
                    @on-input="changeMask"></Slider>
            </Col>
          </Row>
          <RadioGroup v-model="mapStyle1"
                      vertical
                      @on-change="mapStyleChange">
            <Radio label="卫星地图"></Radio>
            <Radio label="平面地图"></Radio>
          </RadioGroup>
          <hr class="clearfix" />
          <Checkbox @on-change="featuresChange">地名</Checkbox>
        </div>
      </div>
    </section>
    <!-- 类型弹窗 -->
    <Modal v-model="typeModal"
           title="事件类型管理"
           width="360"
           footer-hide
           @on-cancel="typeCancel">
      <div class="modal-div">
        <Input type="text"
               v-model.trim="typeInput"
               style="width: 255px"
               show-word-limit
               maxlength="5"
               placeholder="新增类型">
        </Input>
        <div @click="typeAdd"
             class="add-type-btn">新增</div>
      </div>
      <div class="type-container">
        <div class="modal-div"
             v-for="item in typeList"
             :key="item.id">
          <div v-show="!item.edit">{{ item.e_type_name }}</div>
          <Input type="text"
                 v-show="item.edit"
                 show-word-limit
                 maxlength="5"
                 style="width: 255px"
                 v-model.trim="item.e_type_name"
                 @on-enter="editInputTypeName(item)">
          </Input>
          <div>
            <Icon type="ios-create-outline"
                  @click="editTypeName(item)"
                  style="vertical-align: text-top;cursor: pointer;"
                  size="22" />
            <Icon type="md-close"
                  @click="delType(item.id)"
                  style="vertical-align: text-top;cursor: pointer;"
                  size="22" />
          </div>
        </div>
      </div>
    </Modal>
    <!-- 事件弹窗 -->
    <Modal v-model="addModal"
           :title="addIncidentData.e_id?'事件编辑':'新增事件'"
           width="624"
           draggable
           @on-cancel="addCancel">
      <Form ref="addIncidentData"
            :model="addIncidentData"
            :rules="ruleInline"
            inline
            label-position="top">
        <FormItem label="事件名称"
                  prop="e_name">
          <Input v-model.trim="addIncidentData.e_name"
                 style="width: 250px"
                 placeholder="此处填写事件名称"></Input>
        </FormItem>
        <FormItem label="事件类型"
                  prop="e_type">
          <Select v-model="addIncidentData.e_type"
                  style="width: 250px"
                  placeholder="此处选择事件类型">
            <Option v-for="item in typeList"
                    :value="item.id"
                    :key="item.id">{{
              item.e_type_name
            }}</Option>
          </Select>
        </FormItem>
        <FormItem label="任务时间"
                  prop="e_date">
          <DatePicker type="datetime"
                      style="width: 250px"
                      format="yyyy-MM-dd HH:mm"
                      placeholder="设置事件发生时间"
                      v-model="addIncidentData.e_date"></DatePicker>
        </FormItem>
        <FormItem label="备注">
          <Input v-model.trim="addIncidentData.e_remarks"
                 style="width: 250px"
                 maxlength="100"
                 show-word-limit
                 type="textarea"
                 :autosize="{ minRows: 1, maxRows: 5 }"
                 placeholder="此处填写事件备注"></Input>
        </FormItem>
      </Form>
      <p style="font-size:12px;margin:25px 0 5px">位置区域 {{ location }}</p>
      <div class="addMap">
        <div class="mapSearch"
             v-if="$online">
          <Icon type="ios-search"
                style="margin:0 12px"
                size="18"
                color="#777" />
          <input type="text"
                 autocomplete="off"
                 id="mapSearch_1"
                 placeholder="搜索地点" />
        </div>
        <div id="addMap"></div>
      </div>
      <div style="text-align: center"
           slot="footer">
        <Button type="info"
                @click="addIncident"
                :loading="btnLoading"
                style="width: 285px; background: #4c98ff">确认</Button>
      </div>
    </Modal>
    <!-- 删除确认 -->
    <Modal v-model="delModal"
           width="360">
      <p style="textalign: center">
        是否确定删除<span style="color: #ff3366">{{ delName }}</span>
      </p>
      <p style="textalign: center">删除后无法恢复</p>

      <div slot="footer">
        <Button type="error"
                long
                :loading="btnLoading"
                @click="delok">确认删除</Button>
      </div>
    </Modal>
  </div>
</template>

<script>
import {
  IncidentTypeList,
  IncidentTypeAdd,
  IncidentTypeDelete,
  IncidentTypeUpdate,
  IncidentList,
  IncidentAdd,
  IncidentDelete,
  IncidentUpdate,
} from '@/utils/javaApi.js';
export default {
  data() {
    return {
      typeModal: false,
      addModal: false,
      typeList: [], // 类型
      typeChangeList: [],
      typeID: 0, // 当前选中事件类型
      currentType: '',
      eventList: [], // 事件
      typeInput: '', // 事件类型输入框
      addIncidentData: {
        e_name: '',
        e_remarks: '',
        e_type: '',
        e_date: '',
        e_lat: '',
        e_lon: '',
      },
      location: '',
      ruleInline: {
        e_name: [
          { required: true, message: '请填写事件名称', trigger: 'blur' },
          { min: 2, max: 15, message: '事件名称长度2 - 15个字符' },
        ],
        e_type: [
          {
            required: true,
            message: '事件类型不能为空',
            trigger: 'change',
            // 选择器组件的表单规则判断
            pattern: /.+/,
          },
        ],
        e_date: [
          {
            required: true,
            message: '事件时间不能为空',
            trigger: 'change',
            // 选择器组件的表单规则判断
            pattern: /.+/,
          },
        ],
      },
      addMap: {}, // 新增窗口的地图
      btnLoading: false,
      incidentID: '', // 编辑事件的id
      page: 1,
      total: 5,
      delModal: false,
      delName: '',
      delID: '', // 选中事件的id
      searchName: '', // 事件名搜索
      checkID: '',
      moreAct: '-1',
      listmode: true,
      limit: 14,
      layer: '',
      satelliteLayer: '',
      roadNetLayer: '',
      bigMap: '',
      mapStyle1: '卫星地图',
      mapstyleshow: false,
      maskOpacity: 65,
      mask: {},
    };
  },
  mounted() {
    setTimeout(() => {
      console.log('down');
      this.$refs.Incident.style.top = '0';
    }, 100);
    this.getIncidentTypeList();
    this.getIncidentList();
    this.userInfo = JSON.parse(sessionStorage.getItem('user_info'));
    this.checkID = sessionStorage.getItem('Eid');
    window.closeInfoWindow = this.closeInfoWindow;
    window.toRoute = this.toRoute;
  },
  methods: {
    showMore(e) {
      if (this.moreAct == e) {
        this.moreAct = '-1';
      } else {
        this.moreAct = e;
      }
    },
    // 获取事件类型
    getIncidentTypeList() {
      this.$post(IncidentTypeList()).then((res) => {
        this.typeChangeList = [];
        if (res.code === 1) {
          res.data.map((el) => {
            el.edit = false;
          });

          this.typeList = res.data.filter((item) => item.e_type_on > 0);
          this.typeChangeList = this.typeList.concat();
          this.typeChangeList.unshift({ e_type_name: '所有事件', id: 0 });
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },
    // 获取事件列表
    getIncidentList() {
      this.$post(IncidentList(), {
        e_type: this.currentType,
        limit: this.limit,
        page: this.page,
        e_name: this.searchName,
      }).then((res) => {
        if (res.code === 1) {
          this.eventList = res.data.data;
          if (this.listmode) {
            this.total = res.data.total;
            setTimeout(() => {
              this.initMaps(); //加载地图
            }, 400);
          } else {
            setTimeout(() => {
              this.addMarkers();
            }, 500);
          }
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },
    initMaps() {
      this.eventList.map((ele) => {
        new AMap.Map('map' + ele.id, {
          dragEnable: false,
          resizeEnable: false,
          zoom: 16,
          zooms: [16, 16],
          center: [ele.e_lon, ele.e_lat],
          layers: [new AMap.TileLayer.Satellite()],
        });
      });
    },
    initBigMap() {
      this.layer = new AMap.TileLayer();
      // 卫星图
      this.satelliteLayer = new AMap.TileLayer.Satellite();
      this.bigMap = new AMap.Map('bigmap', {
        zoom: 16,
        center: this.$Center,
        layers: [this.layer, this.satelliteLayer],
      });
      this.bigMap.setFeatures(['bg', 'road', 'building']);
    },
    // 添加事件
    addMarkers() {
      this.bigMap.clearMap();
      let outer = [
        new AMap.LngLat(-360, 90, true),
        new AMap.LngLat(-360, -90, true),
        new AMap.LngLat(360, -90, true),
        new AMap.LngLat(360, 90, true),
      ];

      this.mask = new AMap.Polygon({
        path: outer,
        strokeColor: '#000',
        strokeWeight: 1,
        fillColor: '#000',
        fillOpacity: 1 - this.maskOpacity / 100,
        bubble: true,
      });
      this.bigMap.add(this.mask);
      let arr = [];
      this.eventList.map((ele) => {
        this.bigMap;
        let event_marker = new AMap.Marker({
          //   content: plane_content, // 自定义点标记覆盖物内容
          icon: new AMap.Icon({
            size: new AMap.Size(40, 40), // 图标尺寸
            image: require('@/assets/img/Marker/1733.png'), // Icon的图像
            imageSize: new AMap.Size(40, 40), // 根据所设置的大小拉伸或压缩图片
          }),
          position: [parseFloat(ele.e_lon), parseFloat(ele.e_lat)], // 基点位置
          offset: new AMap.Pixel(-20, -20),
          label: {
            content: ele.e_name,
            direction: 'top',
          },
          draggable: false,
        });
        arr.push(event_marker);
        this.bigMap.add(event_marker);
        let info_window = new AMap.InfoWindow({
          isCustom: true, //使用自定义窗体
          content: this.getEventContent(ele),
          //   offset: new AMap.Pixel(16, -45),
          anchor: 'top-left',
        });
        AMap.event.addListener(event_marker, 'click', () => {
          info_window.open(this.bigMap, event_marker.getPosition());
        });
      });
      this.bigMap.setFitView(arr);
    },

    mapStyleChange(e) {
      console.log(e);
      if (e == '平面地图') {
        this.bigMap.remove(this.satelliteLayer);
      } else {
        this.bigMap.add(this.satelliteLayer);
      }
    },
    // 蒙版透明度
    changeMask(e) {
      this.mask.setOptions({ fillOpacity: 1 - this.maskOpacity / 100 });
    },
    featuresChange(e) {
      if (e) {
        this.bigMap.setFeatures(['bg', 'road', 'building', 'point']);
      } else {
        this.bigMap.setFeatures(['bg', 'road', 'building']);
      }
    },
    // 事件dom
    getEventContent(ele) {
      let img1 = require('@/assets/img/Marker/线段.png');
      let img2 = require('@/assets/img/Icon/task_task.png');
      let img3 = require('@/assets/img/Marker/历史轨迹.png');
      let img4 = require('@/assets/img/statistics/swmx.png');
      let img5 = require('@/assets/img/statistics/ewdt.png');
      let img6 = require('@/assets/img/statistics/qjzp.png');
      let content = `
                <div class="map-event-info">
                    <div style="text-align:right"><i class="ivu-icon ivu-icon-md-close" onclick="closeInfoWindow()" style="color:#000;font-size:18px;cursor:pointer;margin-top:5px"></i></div>
                    <div class="map-event-info-item map-event-info-box" onclick="toRoute('${ele.id}','${ele.e_name}',1)"><img src="${img1}" /><p>航线</p><span>${ele.e_air_number}</span></div>
                    <div class="map-event-info-item map-event-info-box" onclick="toRoute('${ele.id}','${ele.e_name}',2)"><img src="${img2}" /><p>未完成任务</p><span>${ele.e_task_no_complete}</span></div>
                    <div class="map-event-info-item map-event-info-box" onclick="toRoute('${ele.id}','${ele.e_name}',3)"><img src="${img3}" /><p>飞行轨迹</p><span>${ele.e_tarck_number}</span></div>
                    <div class="map-event-info-box">
                        <div class="map-event-info-item" onclick="toRoute('${ele.id}','${ele.e_name}',4)"><img src="${img4}" /><p>三维模型</p><span>${ele.e_model_number}</span></div>
                        <div class="map-event-info-item" onclick="toRoute('${ele.id}','${ele.e_name}',5)"><img src="${img5}" /><p>二维地图</p><span>${ele.e_kml_number}</span></div>
                        <div class="map-event-info-item" onclick="toRoute('${ele.id}','${ele.e_name}',6)"><img src="${img6}" /><p>全景照片</p><span>${ele.e_Para_number}</span></div>
                    </div>

            </div>
        `;
      return content;
    },
    // 切换大地图
    toMap() {
      /**
       * 初始化参数
       * 销毁小地图
       * 填充数据(点的dom)
       */

      this.listmode = false;
      this.limit = this.total;
      this.page = 1;
      this.$nextTick(() => {
        this.initBigMap();
      });
      //   setTimeout(() => {
      //     this.initBigMap();
      //   }, 400);
      this.getIncidentList();
    },
    closeInfoWindow() {
      this.bigMap.clearInfoWindow();
    },
    toRoute(id, name, path) {
      this.checkedEvent({ id: id, e_name: name });
      switch (path) {
        case 1:
          this.$router.push('/index/project');
          break;
        case 2:
          this.$router.push('/index/task/task');
          break;
        case 3:
          this.$router.push('/statistics/records');
          break;
        case 4:
          this.$router.push('/index/resource/modal');
          break;
        case 5:
          this.$router.push('/index/resource/ermodal');
          break;
        case 6:
          this.$router.push('/index/resource/panorama');
          break;
        default:
          break;
      }
    },
    toList() {
      /**
       * 初始化参数
       * 销毁大地图
       */
      this.bigMap.destroy();
      this.listmode = true;
      this.limit = 14;
      this.page = 1;
      this.getIncidentList();
    },
    // 翻页
    changePage(ind) {
      this.page = ind;
      this.getIncidentList();
    },
    // 选中事件
    checkedEvent(el) {
      sessionStorage.setItem('Eid', el.id);
      sessionStorage.setItem('Ename', el.e_name);
      this.backhomepage();
    },
    // 切换类型
    typeChange(id) {
      console.log(id);
      if (id === 0) {
        this.currentType = '';
      } else {
        this.currentType = id;
      }
      this.page = 1;
      this.getIncidentList();
    },

    // 事件搜索
    search() {
      this.typeID = 0;
      this.page = 1;
      this.currentType = '';
      this.getIncidentList();
    },
    clearSearch() {
      this.typeID = 0;
      this.searchName = '';
      this.currentType = '';
      this.page = 1;
      this.getIncidentList();
    },

    /**
     * 事件类型
     */
    // 新增事件类型
    typeAdd() {
      if (this.typeInput == '') {
        this.$Message.error('未填写事件类型');
        return false;
      }
      this.$post(IncidentTypeAdd(), {
        e_type_name: this.typeInput,
      }).then((res) => {
        if (res.code === 1) {
          this.$Message.success(res.msg_customer);
          this.typeInput = '';
          this.getIncidentTypeList();
        } else {
          this.$Message.error(res.msg_customer);
        }
      });
    },
    // 修改事件类型
    editTypeName(item) {
      this.typeList.map((el) => (el.edit = false));
      item.edit = !item.edit;
    },
    editInputTypeName(item) {
      this.$post(IncidentTypeUpdate(), {
        id: item.id,
        e_type_name: item.e_type_name,
      }).then((res) => {
        if (res.code === 1) {
          this.$Message.success(res.msg_customer);
          this.getIncidentTypeList();
          this.getIncidentList();
        } else {
          this.$Message.warning(res.msg_customer);
        }
      });
    },
    // 删除事件类型
    delType(id) {
      this.$post(IncidentTypeDelete(), {
        id: id,
      }).then((res) => {
        if (res.code === 1) {
          this.$Message.success(res.msg_customer);
          this.getIncidentTypeList();
        } else {
          this.$Message.warning(res.msg_customer);
        }
      });
    },
    typeCancel() {
      this.typeInput = '';
      this.typeList.map((el) => (el.edit = false));
    },

    /**
     *  新增、编辑事件
     */
    addModalshow() {
      let that = this;
      let marker = '';
      this.addModal = true;
      this.addMap = new AMap.Map('addMap', {
        center: this.$Center,
        expandZoomRange: true, // 扩展地图缩放级别
        zoom: 16,
        zooms: [3, 20],
        resizeEnable: true,
      });
      if (this.$online) {
        this.$nextTick(() => {
          //   this.addMap.setMapStyle('amap://styles/whitesmoke');
          let autoComplete = new AMap.Autocomplete({ input: 'mapSearch_1' });
          console.log(autoComplete);
          AMap.event.addListener(autoComplete, 'select', function (e) {
            console.log(e);
            that.addMap.setCenter([e.poi.location.lng, e.poi.location.lat]);
          });
        });
      }
      if (Boolean(this.addIncidentData.e_id)) {
        marker = marker = new AMap.Marker({
          position: [that.addIncidentData.e_lon, that.addIncidentData.e_lat],
          map: that.addMap,
        });
        that.getAddsByPosition([
          that.addIncidentData.e_lon,
          that.addIncidentData.e_lat,
        ]);
      }
      this.addMap.on('click', function (e) {
        that.addIncidentData.e_lat = e.lnglat.Q;
        that.addIncidentData.e_lon = e.lnglat.R;
        if (this.$online) {
          that.getAddsByPosition(e.lnglat);
        }
        if (marker == '') {
          marker = new AMap.Marker({
            position: e.lnglat,
            map: that.addMap,
          });
        } else {
          marker.setPosition(e.lnglat);
        }
      });
    },
    getAddsByPosition(mylnglat) {
      let that = this;
      this.addMap.plugin('AMap.Geocoder', function () {
        let geocoder = new AMap.Geocoder();
        geocoder.getAddress(mylnglat, function (status, result) {
          if (status === 'complete' && result.info === 'OK') {
            that.location = result.regeocode.formattedAddress;
          }
        });
      });
    },
    // 提交新增或编辑
    addIncident() {
      if (this.addIncidentData.e_lat == '') {
        this.$Message.error('未选择事件位置');
        return false;
      }

      this.$refs['addIncidentData'].validate((valid) => {
        if (valid) {
          this.addIncidentData.e_date = this.$moment(
            this.addIncidentData.e_date
          ).format('YYYY-MM-DD HH:mm:ss');
          console.log(this.addIncidentData.e_date);
          this.btnLoading = true;
          let url;
          if (Boolean(this.addIncidentData.e_id)) {
            url = IncidentUpdate();
          } else {
            url = IncidentAdd();
          }
          this.$post(url, this.addIncidentData)
            .then((res) => {
              if (res.code === 1) {
                this.$Message.success(res.msg_customer);
                this.addModal = false;
                this.addCancel();
                this.getIncidentList();
              } else {
                this.$Message.error(res.msg_customer);
              }
            })
            .catch(() => {
              // 取消按钮加载
              this.btnLoading = false;
            });
        }
      });
    },
    // 取消新增事件
    addCancel() {
      this.btnLoading = false;
      this.$refs['addIncidentData'].resetFields();
      this.incidentID = '';
      this.addIncidentData = {
        e_name: '',
        e_remarks: '',
        e_type: '',
        e_date: '',
        e_lat: '',
        e_lon: '',
        e_id: '',
      };
      this.location = '';
      this.addMap.destroy();
      this.addMap = {};
    },
    // 事件编辑
    edit(item) {
      this.addIncidentData = {
        e_name: item.e_name,
        e_remarks: item.e_remarks,
        e_type: item.type_id,
        e_date: item.e_date,
        e_lat: item.e_lat,
        e_lon: item.e_lon,
        e_id: item.id,
      };
      this.addModalshow();
    },
    // 事件删除
    del(item) {
      if (this.userInfo.is_manager == 0) {
        this.$Message.warning('该账户没有删除权限');
      } else {
        this.delModal = true;
        this.delName = item.e_name;
        this.delID = item.id;
      }
    },
    delok() {
      this.btnLoading = true;
      this.$post(IncidentDelete(), {
        ids: this.delID.toString(),
      })
        .then((res) => {
          if (res.code === 1) {
            this.$Message.success(res.msg_customer);
            this.delModal = false;
            this.delName = '';
            this.delID = '';
            this.getIncidentList();
          } else {
            this.$Message.warning(res.msg_customer);
          }
          // 取消按钮加载
          this.btnLoading = false;
        })
        .catch(() => {
          // 取消按钮加载
          this.btnLoading = false;
        });
    },

    // 退出事件选择
    backhomepage() {
      this.$refs.Incident.style.top = '-100%';
      setTimeout(() => {
        this.$emit('hideIncident');
      }, 800);
    },
    // 取消事件选择
    uncheck() {
      sessionStorage.setItem('Eid', null);
      sessionStorage.setItem('Ename', '');
      this.backhomepage();
    },
  },
  beforeDestroy() {
    window.closeInfoWindow = null;
    window.toRoute = null;
  },
};
</script>

<style scoped lang="scss">
#Incident {
  min-width: 670px;
  width: 100%;
  height: 100vh;
  background-color: $xf_hui1;
  position: absolute;
  top: -100%;
  //   top: 0;
  z-index: 600;
  color: $white;
  transition: top 0.5s;

  aside {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    background-color: $xf_hei1;
    align-items: center;
    width: 100%;
    height: 54px;
    .back {
      width: 139px;
      height: 23px;
      cursor: pointer;
      font-size: 12px;
      color: $font_color_1;
      text-align: center;
      background-color: $xf_hui7;
      line-height: 23px;
      margin-right: 16px;
    }
    .back:hover {
      background-color: $xf_hui7_hover;
    }
  }
  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 0.21rem;
    padding: 0 3.22rem;
    p {
      font-size: 16px;
    }
    button {
      //   width: 103px;
      font-size: 14px;
    }
    .type-btn {
      margin-right: 0.3rem;
      //   border: 1px solid #2183f3;
      //   color: #2183f3;
    }
  }
  main {
    padding: 0 3.12rem;
    margin-top: 0.3rem;
    .incident-add {
      float: left;
      width: 2.36rem;
      height: 2.16rem;
      margin-right: 0.24rem;
      border: 1px solid #000;
      border-radius: 7px;
      text-align: center;
      padding-top: 0.76rem;
      cursor: pointer;
      // background-color: $xf_hei1;
      color: $font_color_1;
    }
    .incident-add:hover {
      background-color: $xf_hei1;
    }
    .incident-box {
      float: left;
      width: 2.36rem;
      height: 2.16rem;
      border-radius: 7px;
      margin-right: 0.24rem;
      margin-bottom: 0.25rem;
      cursor: pointer;
      position: relative;
      background: $xf_hei1;
      transition: all 0.5s;
      .type-name {
        position: absolute;
        border-radius: 7px 0 0 0;
        top: 0;
        left: 0;
        z-index: 10;
        padding: 0 5px;
        background-color: rgba(0, 0, 0, 0.5);
        color: #c9caca;
      }
      .map {
        width: 100%;
        height: 1.11rem;
        border-radius: 7px 7px 0 0;
      }
      .info {
        padding: 10px 10px 0;
        color: #a0a0a0;
        font-size: 0.12rem;
        .title {
          color: $huiC;
          font-size: 0.14rem;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 2rem;
        }
        .remark {
          margin-top: 0.13rem;
          color: #656565;
          width: 2rem;
          overflow: hidden; /* 溢出隐藏 */
          display: -webkit-box; /* 老版弹性盒子，存在浏览器兼容问题，需要添加-webkit，表示内容在水平方向上的排放 */
          -webkit-line-clamp: 2; /* 溢出的行数 */
          -webkit-box-orient: vertical; /* 规定内容水平排放 */
          text-overflow: ellipsis;
          white-space: pre;
        }
        .more {
          position: absolute;
          top: 1.3rem;
          right: 0.05rem;
          > div {
            display: none;
            position: absolute;
            width: 1.06rem;
            height: 1rem;
            // box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
            border: 1px solid #ccc;
            border-radius: 4px;
            background-color: $xf_hei4;
            top: 25px;
            right: 4px;
            z-index: 9;
            overflow: hidden;
            p {
              width: 100%;
              color: $font_color_1;
              height: 0.5rem;
              font-size: 0.14rem;
              line-height: 0.5rem;
              text-align: center;
            }
            p:hover {
              background-color: $xf_hui7_hover;
            }
          }
        }
        // .more:hover div {
        //   display: block;
        // }
        .more:hover {
          height: 22px;
          border-radius: 50%;
          background-color: $xf_hui3;
        }
      }
    }
    .incident-box:nth-child(5n) {
      margin-right: 0;
    }
    .incident-box:hover {
      transform: translateY(-5px);
    }
    .check-box {
      box-shadow: 0 0 0px 2px #2082f3;
      .selected {
        width: 2.36rem;
        height: 1.11rem;
        background: rgba(0, 0, 0, 0.5);
        border-radius: 7px 7px 0px 0px;
        position: absolute;
        top: 0;
        left: 0;
        color: $font_color_1;
        z-index: 15;
        text-align: center;
        line-height: 1.11rem;
        font-size: 14px;
      }
    }
  }
  section {
    width: 100vw;
    height: calc(100vh - 54px);
    position: relative;
    .typeList {
      position: absolute;
      display: flex;
      flex-wrap: wrap;
      top: 0;
      left: 0;
      z-index: 150;
      div {
        cursor: pointer;
        margin: 10px;
        padding: 0 18px;
        color: #464d54;
        height: 34px;
        line-height: 34px;
        border-radius: 17px;
        background-color: #fff;
      }
      .check-box {
        color: #fff;
        background-color: #0773ca;
      }
    }
    #bigmap {
      width: 100%;
      height: 100%;
    }
    .hierarchyBar {
      position: absolute;
      bottom: 15px;
      right: 15px;
      background-color: rgb(1, 1, 1);
      padding: 5px;
      cursor: pointer;

      img {
        width: 20px;
      }
    }

    .hierarchyBox {
      position: absolute;
      bottom: 10px;
      right: 40px;
      background-color: rgba(1, 1, 1, 0.7);
      color: #fff;
      padding: 5px 10px;
      border-radius: 8px;
      width: 160px;
      .clearfix {
        margin-bottom: 8px;
        margin-top: 8px;
      }
    }
    /deep/ .amap-marker-content {
      width: 40px;
      height: 50px;
      white-space: nowrap !important;
    }

    //   > p {
    //     color: #fff;
    //     width: 110px;
    //     height: 20px;
    //     line-height: 20px;
    //     overflow: hidden;
    //     background-color: rgba(0, 0, 0, 0.8);
    //   }
    /deep/ .map-event-info {
      white-space: nowrap;
      // visibility: hidden;
      cursor: default;
      width: 197px;
      background-color: #fff;
      border-radius: 8px;
      padding: 0 13px 10px;
      .map-event-info-item {
        cursor: pointer;
        padding: 8px 12px;
        color: #000;
        img {
          width: 14px;
          margin-right: 6px;
          display: inline-block;
        }
        p {
          display: inline-block;
        }
        span {
          float: right;
          color: #888;
        }
      }
      .map-event-info-box {
        background: #f0f0f0;
        border-radius: 7px;
        margin-bottom: 5px;
      }
    }
  }
}
.type-container {
  max-height: 3.6rem;
  overflow-x: auto;
}
.type-container::-webkit-scrollbar {
  width: 2px;
}
.type-container::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  background: #0dcbfc;
}
// .type-container::-webkit-scrollbar-track {
//   /*滚动条里面轨道*/
//   box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//   background: #ededed;
// }

.modal-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 34px;
  line-height: 34px;
  margin: 5px 0;
  .add-type-btn {
    cursor: pointer;
    color: #2183f3;
  }
}
.addMap {
  width: 592px;
  height: 300px;
  position: relative;
  .mapSearch {
    position: absolute;
    top: 14px;
    left: 11px;
    z-index: 400;
    width: 290px;
    height: 40px;
    background: rgba(23, 23, 23, 0.71);
    border: 1px solid #454545;
    line-height: 40px;
    text-align: left;

    input {
      background: transparent;
      outline: none;
      border: 0;
      color: #fff;
    }
  }
  #addMap {
    width: 100%;
    height: 100%;
  }
}

.ivu-btn-default {
  background-color: $xf_hui1;
}
/deep/.ivu-input {
  background-color: $xf_hui1 !important; // transparent #161516
  border: none !important;
}
</style>
